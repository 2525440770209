import React from 'react';

import { Container, Grid, Card, CardContent, Typography, Button, Box } from '@mui/material';

function HomePage() {
  const topMargin = '50px'; // Adjust based on AppBar's height
  const borderRadiusValue = '15px'; // Rounded corners for cards

  return (
    <Box sx={{ minHeight: '100vh', backgroundColor: '#202225', p: 3, pt: topMargin }}>
      <Container maxWidth="lg">
   

      <Grid container justifyContent="center">
          <Grid item xs={12}>
    
         {/* Main Heading - Example with a new color code */}
        <Typography variant="h2" component="h1" gutterBottom align="left" sx={{ color: '#FFDAB9', textAlign: 'center', mb: 2 }}>
            Explore DeFi & DApp integrations Effortlessly
         </Typography>

         <Typography variant="h5" component="h2" gutterBottom align="left" sx={{ textAlign: 'center', mb: 2 }}>
         <span style={{ color: '#FFFFFF' }}>(</span>
    <span style={{ color: '#A9A9A9' }}>Powered by </span>
    <span style={{ color: '#7b86c7' }}>Ethereum</span>
    <span style={{ color: '#FFFFFF' }}>)</span>
</Typography>



        {/* Third Heading - Maintained original color code for consistency */}
        <Box sx={{ width: '50%', minWidth: '300px', margin: '0 auto' }}> {/* Adjust width as needed */}
          <Typography variant="body1" component="h2" gutterBottom align="left" sx={{ color: '#ccc', textAlign: 'center', mt: 4, mb: 4 }}>
          Enhance your DeFi and DApp use on one platform. Manage crypto with FLUSD, a stablecoin for simple conversion. Develop, test, and deploy DApps with smart contract APIs.  </Typography>
        </Box>

        



          </Grid>







 
        <Grid container spacing={3} justifyContent="center">
  


          {/* Staking Card */}
          <Grid item xs={12} md={8}>

        

            <Card sx={{
              backgroundColor: '#0a0710',
              fontSize: '1rem',
              fontFamily: 'Arial',
              borderRadius: borderRadiusValue,
              overflow: 'hidden',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              height: '380px',
            }}> 
      
      <Box sx={{ pt: 2, pb: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', height: '10px' }}>
 <Typography variant="h7" sx={{ textAlign: 'center', color: '#FFFFFF' }}>
    <span style={{ color: '#FFFFFF' }}>(</span>
    <span style={{ color: '#A9A9A9' }}> Input your Wallet </span>
    <span style={{ color: '#A9A9A9' }}> address </span>
    <span style={{ color: '#A9A9A9' }}> from  </span>
    <span style={{ color: '#A9A9A9' }}> Metamask,  </span>
    <span style={{ color: '#A9A9A9' }}> MyEtherWallet </span>
    <span style={{ color: '#A9A9A9' }}> or </span>
    <span style={{ color: '#A9A9A9' }}> similar </span>
    <span style={{ color: '#FFFFFF' }}>)</span>
 </Typography>
</Box>

              <CardContent sx={{ textAlign: 'center', height: '100%' }}>
                <iframe
                  src="https://fluidbase.web.app"
                  style={{ width: '100%', height: '100%', border: 'none' }}
                  title="Embedded Webpage"
                  allowFullScreen
                ></iframe>
              </CardContent>
            </Card>
          </Grid>

          {/* Other Card 1 */}
          <Grid item xs={12} sm={6} md={4}>
            <Card sx={{
              backgroundColor: ' #FFBF00',
              mb: 2,
              borderRadius: borderRadiusValue
            }}>
              <CardContent>
                {/* Ether Box */}
                <Typography variant="h5" component="div" sx={{ bgcolor: '#D4AF37', color: 'white', fontWeight: 'bold', borderRadius: '10px', display: 'inline-block', px: 1, py: 0.05, mb: 1 }}>
                  Ether
                </Typography>

                <Typography sx={{ fontSize: '16px', color: '#0a0710', fontWeight: 'bold' }}>
                  2.90% APR
                </Typography>

                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                  <Button variant="contained" sx={{ backgroundColor: '#4B4B4C', color: '#ffffff', mr: 1, borderRadius: '20px', fontWeight: 'bold' }}>
                    Start staking
                  </Button>
                  <Button variant="outlined" sx={{ backgroundColor: '#4B4B4C', color: '#ffffff', borderRadius: '20px', fontWeight: 'bold' }}>
                    View details
                  </Button>
                </Box>
              </CardContent>
            </Card>

            {/* Other Card 2 */}
            <Card sx={{
              backgroundColor: '#1B4D3E',
              mb: 2,
              borderRadius: borderRadiusValue
            }}>
              <CardContent>
                {/* Polygon Box */}
                <Typography variant="h5" component="div" sx={{ bgcolor: '#367588', color: 'white', fontWeight: 'bold', borderRadius: '10px', display: 'inline-block', px: 1, py: 0.05, mb: 1 }}>
                  Polygon
                </Typography>

                <Typography sx={{ fontSize: '16px', color: '#0a0710', fontWeight: 'bold' }}>
                  3.52% APR
                </Typography>

                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                  <Button variant="contained" sx={{ backgroundColor: '#4B4B4C', color: '#ffffff', mr: 1, borderRadius: '20px', fontWeight: 'bold' }}>
                    Start staking
                  </Button>
                  <Button variant="outlined" sx={{ backgroundColor: '#4B4B4C', color: '#ffffff', borderRadius: '20px', fontWeight: 'bold' }}>
                    View details
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>





        </Grid>
      </Container>
     
    </Box>
  );
}

export default HomePage;
