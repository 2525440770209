// Import necessary components, libraries, and the uuid function
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import ApiKeyCard from './ApiKeyCard'; // Import ApiKeyCard component
import { v4 as uuidv4 } from 'uuid'; // Import the v4 function from the uuid library

function GenerateApiKeyModal({ isOpen, onClose }) {
  const [apiKeyName, setApiKeyName] = useState('');
  const [generatedApiKey, setGeneratedApiKey] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);

  const handleSubmit = async () => {
    setIsGenerating(true);
    try {
      // Generate a UUID as the API key
      const apiKey = uuidv4();

      // Optionally, you could prepend or append the apiKeyName to the UUID if it needs to be part of the key
      // const namedApiKey = `${apiKeyName}-${apiKey}`;

      setGeneratedApiKey(apiKey); // Store the generated API key
    } catch (error) {
      console.error('Error generating API key:', error);
      // Optionally, handle error state here
    }
    setIsGenerating(false);
    // onClose();
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
      }}>
        <Typography id="modal-title" variant="h6" component="h2" sx={{ color: '#3a2092' }}>
          Please Enter a Name for Your API Key
        </Typography>

        <Box component="form" sx={{ mt: 2 }} noValidate autoComplete="off">
          <TextField
            fullWidth
            label="API Key Name"
            value={apiKeyName}
            onChange={(e) => setApiKeyName(e.target.value)}
            margin="normal"
            sx={{ '& .MuiOutlinedInput-root': { borderRadius: '8px' }}}
          />

          <Button variant="outlined" sx={{ mt: 2, mr: 1, color: '#0a0710', borderColor: '#0a0710', '&:hover': { borderColor: '#115293', backgroundColor: '#f5f5f5' }, borderRadius: '20px' }} onClick={onClose}>
            Cancel
          </Button>

          <Button
  variant="contained"
  disabled={isGenerating}
  sx={{
    mt: 2,
    bgcolor: '#0a0710', // Set the background color to violet
    color: 'white', // Set text color to white for better readability
    borderRadius: '20px', // Set rounded corners like the Cancel button
    '&:hover': {
      bgcolor: '#7B1FA2' // Darker shade of violet for hover state
    }
  }}
  onClick={handleSubmit}
>
  {isGenerating ? 'Generating...' : 'Generate'}
</Button>




        </Box>
        {generatedApiKey && <ApiKeyCard apiKey={generatedApiKey} />}
      </Box>
    </Modal>
  );
}

export default GenerateApiKeyModal;

