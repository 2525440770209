import React from 'react';
import { Box, Typography, Container } from '@mui/material';
import PageLayout from '../common/PageLayout';
import SiteFooter from '../common/SiteFooter';


const APIendpoint = () => {
 return (
   <PageLayout>
 
    <Container sx={{
      mt: 8, 
      mb: 8, 
      display: 'flex', 
      justifyContent: 'flex-start', // Align to the left instead of center
      maxWidth: '100%', // Ensure the Container can expand full width
      backgroundColor: '#000000', // Black background for contrast
    }}>
      <Box sx={{ 
        maxWidth: '800px', // Maximum width of the content
        width: '100%', // Take up 100% of the container width
        textAlign: 'left',
        paddingLeft: '16px', // Adjust left padding to move text more to the left
        paddingRight: 2, // Right padding for some spacing
      }}>
       <Typography variant="h3" sx={{ fontWeight: 'bold', mb: 3, textAlign: 'left' }}>
          <span style={{ color: '#FFA500' }}>Base API</span>
          <span style={{ color: '#FFFFFF' }}> Endpoint</span>
       </Typography>

     
        <Typography variant="h5" sx={{ mb: 4, textAlign: 'left', color: '#FFFFFF' }}>
         <span style={{ color: '#FFFFFF' }}>(</span>
         <span style={{ color: '#A9A9A9' }}> For both </span>
    <span style={{ color: '#FFFFFF' }}> Web </span>
    <span style={{ color: '#A9A9A9' }}> & </span>
    <span style={{ color: '#FFFFFF' }}> Mobile </span>
    <span style={{ color: '#48d1cc' }}> Integration</span>
    <span style={{ color: '#FFFFFF' }}>)</span>
        </Typography>



        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2, textAlign: 'left', color: '#FFFFFF' }}>
          Getting Started
        </Typography>


        <Typography sx={{ mb: 2, color: '#FFFFFF' }}>
        The API endpoint for checking ETH wallet balances is as follows:
        </Typography>
        <Typography sx={{ mb: 2, color: '#FFFFFF' }}>
           <span style={{ color: '#FFA500' }}>Endpoint URL :   </span>
           <a href="https://us-central1-fluidbase.cloudfunctions.net/fluidbasegetBalance" 
            style={{ color: '#48d1cc', textDecoration: 'none' }} 
            target="_blank" 
            rel="noopener noreferrer">   
               https://us-central1-fluidbase.cloudfunctions.net/fluidbasegetBalance
           </a>
        </Typography>





        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2, textAlign: 'left', color: '#FFFFFF' }}>
        Making API Requests
        </Typography>
        <Typography sx={{ mb: 2, color: '#FFFFFF' }}>
        To check the balance of an ETH wallet address, make an HTTP GET request to the API endpoint with the address query parameter. The API will respond with the balance in ETH.
        </Typography>
    
        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2, textAlign: 'left', color: '#FFFFFF' }}>
          Example API Request:
        </Typography>



                <Box sx={{
             backgroundColor: '#333333', // Slightly white transparent background
             borderRadius: '8px', // Rounded corners
             p: 2, // Padding inside the box
             mb: 6, // Margin bottom for spacing
             boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)', // Shadow for depth
             fontFamily: 'monospace', // Font for code
  
            }}>
     
   
                 <iframe
                    src="https://fluidspot.s3.us-east-2.amazonaws.com/web/Base/APIep1.html"
                    style={{ width: '100%', height: '230px', border: 'none' }}
                    title="Embedded Webpage"
                    allowFullScreen
                ></iframe>


                </Box>



                <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2, textAlign: 'left', color: '#FFFFFF' }}>
                Response Format
        </Typography>
        <Typography sx={{ mb: 2, color: '#FFFFFF' }}>
       The API responds with a plain text representation of the ETH balance.
        </Typography>
    
        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2, textAlign: 'left', color: '#FFFFFF' }}>
          Example API Request:
        </Typography>


   

        <Box sx={{
             backgroundColor: '#333333', // Slightly white transparent background
             borderRadius: '8px', // Rounded corners
             p: 2, // Padding inside the box
             mb: 6, // Margin bottom for spacing
             boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)', // Shadow for depth
             fontFamily: 'monospace', // Font for code
  
            }}>
     
   
                 <iframe
                    src="https://fluidspot.s3.us-east-2.amazonaws.com/web/Base/APIep7.html"
                    style={{ width: '100%', height: '180px', border: 'none' }}
                    title="Embedded Webpage"
                    allowFullScreen
                ></iframe>


                </Box>





                <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2, textAlign: 'left', color: '#FFFFFF' }}>
                Error Handling
        </Typography>
        <Typography sx={{ mb: 2, color: '#FFFFFF' }}>
        If there is an error or if the provided wallet address is invalid, the API will respond with an error message.
        </Typography>


        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2, textAlign: 'left', color: '#FFFFFF' }}>
        Authentication :
        </Typography>
        <Typography sx={{ mb: 2, color: '#FFFFFF' }}>
        Please Refer the  "API Suite"
        </Typography>




        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2, textAlign: 'left', color: '#FFFFFF' }}>
        Support and Contact:
        </Typography> 

        <Typography sx={{ mb: 2, color: '#FFFFFF' }}>
       For questions, issues, or support, please contact us at{' '}
        <a href="mailto:fluid@fluid-spot.com" style={{ color: '#FFA500', textDecoration: 'none' }}>fluid@fluid-spot.com</a>
        </Typography>

        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2, textAlign: 'left', color: '#FFFFFF' }}>
        Legal Considerations
        </Typography> 

        <Typography sx={{ mb: 2, color: '#FFFFFF' }}>
        By using this API, you agree to our Terms of Use and{' '}
        <a href="https://www.privacypolicies.com/live/5331ad6a-97ad-46fd-bc6d-2f120a3fa4e1" style={{ color: '#FFA500', textDecoration: 'none' }}>Privacy Policy</a>
        </Typography>



      </Box>
    </Container>
   
   <SiteFooter />
    </PageLayout>
   
  
 );
};

export default APIendpoint;

