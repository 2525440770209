import Container from 'react-bootstrap/Container';

function SiteFooter() {
  return (
    <footer className="py-3" style={{ backgroundColor: '#000', color: 'white' }}>
      <Container>
        <p className="text-end mb-0">&copy; Fluid 2024</p>
      </Container>
    </footer>
  );
}

export default SiteFooter;
