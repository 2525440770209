import React, { useState } from 'react';
import PageLayout from '../common/PageLayout';
import { Container, Grid, Card, CardContent, Typography, IconButton, Box } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';


const DappsPage = () => {
  const navigate = useNavigate();
  const [dapps, setDapps] = useState([]);

  const addDapp = () => {
    const newDapp = {
      id: dapps.length,
      name: `Dapp ${dapps.length + 1}`,
      date: new Date().toISOString().split('T')[0],
    };
    setDapps([...dapps, newDapp]);
  };



  const deleteDapp = (id) => {
    setDapps(dapps.filter((dapp) => dapp.id !== id));
  };

  const openDapp = (id) => {
    // This function would navigate to the dapp detail view
    // Replace '/dapp-detail' with the correct path and pass the dapp id as needed
    navigate(`/dapps/${id}`);
  };

  const navigateToCreateDapp = () => {
    navigate('/dapps/create');
  };
  
  


  const cardStyle = {
    backgroundColor: '#2D2D2D',
    color: '#FFFFFF',
    borderRadius: '15px',
    height: '150px',
    position: 'relative',
    '&:hover': {
      backgroundColor: '#373737',
      cursor: 'pointer',
    },
  };

  return (
    <PageLayout>



      <Box sx={{ minHeight: '100vh', backgroundColor: '#202225', p: 3 }}>
      <Container maxWidth="lg">

      <Box sx={{ 
        mt: 4, // Adjusted to match the top margin of the first Typography
        mb: 2, // Bottom margin to space it from the next Box
        alignItems: 'center',
        pl: 6, // Aligns text to the left
      }}>
       <Typography variant="h3" sx={{ 
         fontWeight: 'bold', 
         mb: 3, // Space between this Typography and the next one
       }}>
        <span style={{ color: '#FFE5B4' }}>DApp </span>
        <span style={{ color: '#FFFFFF' }}>  Smart Contract  Development  <br />  Platform</span>
       </Typography>

        

        <Typography variant="h6" sx={{ 
            fontWeight: 'bold', 
            color: '#FFFFFF', mb: 2,
          }}>
          An IDE for Everyone !!  
        </Typography>

        <Typography sx={{ mb: 2, color: '#FFFFFF' }}>
        The DApp platform enables developers to build, test, and deploy DApps on various EVM-compatible chains, <br />  including Ethereum, Polygon, and Avalanche & more. It also allows for the integration of smart contract API protocols <br /> into their platforms through the use of secure Fluid API keys
        </Typography>


    </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mt: 4, mb: 4 }}> {/* Adjust top and bottom margins as needed */}
        <img
          src="https://fluidspot.s3.us-east-2.amazonaws.com/web/Base/media_files/Dapp10.png" // Replace with your actual logo URL
          alt="Logo"
          style={{ height: '60px', marginRight: '16px' }} // Adjust size and spacing as needed
        />
        
<Typography 
  id="modal-title" 
  variant="h6" 
  component="h2" 
  sx={{ 
    color: '#ffffff', // This color applies to the whole text by default
    fontSize: '2rem', // Adjust font size as needed
  }}
>
  Dapps
</Typography>


      </Box>
        

          <Grid container spacing={3}>
            {/* Plus Card */}
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Card sx={cardStyle} onClick={addDapp}>
                <CardContent>
                  <AddCircleOutlineIcon fontSize="large" />
                  <Typography variant="h5">Create Dapp</Typography>
                </CardContent>
              </Card>
            </Grid>
            {/* Dynamically generated Dapp Cards */}
            {dapps.map((dapp) => (
             <Grid item xs={12} sm={6} md={4} lg={3} key={dapp.id}>
             {/* Add onClick event directly to the Card component */}
             <Card sx={cardStyle} onClick={() => openDapp(dapp.id)}>
               <CardContent>
                 <Typography variant="h5" component="div">
                   {dapp.name}
                 </Typography>
                 <Typography variant="body2">{dapp.date}</Typography>
               </CardContent>
               {/* IconButton to delete the Dapp, with stopPropagation to prevent card onClick */}
               <IconButton
                 onClick={(event) => {
                   event.stopPropagation(); // Prevent triggering the card's click event
                   deleteDapp(dapp.id);
                 }}
                 style={{
                   position: 'absolute',
                   top: '5px',
                   right: '5px',
                 }}
               >
                 <DeleteIcon />
               </IconButton>
             </Card>
           </Grid>
           
            ))}
          </Grid>
        </Container>
      </Box>
    </PageLayout>
  );
}

export default DappsPage;
