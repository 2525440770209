import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Snackbar from '@mui/material/Snackbar';

function ApiKeyCard({ apiKey }) {
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(apiKey);
      setOpenSnackbar(true);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Card sx={{ minWidth: 275, marginTop: 2, backgroundColor: 'black', color: 'white' }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 , color: 'violet' }} gutterBottom>
          Your API Key
        </Typography>
        <Typography variant="h5" component="div">
          {apiKey}
        </Typography>
        <IconButton onClick={handleCopy} aria-label="copy" size="large">
          <ContentCopyIcon style={{ color: 'white' }} />
        </IconButton>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          message="API Key copied to clipboard"
        />
      </CardContent>
    </Card>
  );
}

export default ApiKeyCard;
