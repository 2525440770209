import React from 'react';
import { Box, Typography, Container } from '@mui/material';
import PageLayout from '../common/PageLayout';
import SiteFooter from '../common/SiteFooter';


const APIdocs = () => {
 return (
   <PageLayout>
 
    <Container sx={{
      mt: 8, 
      mb: 8, 
      display: 'flex', 
      justifyContent: 'flex-start', // Align to the left instead of center
      maxWidth: '100%', // Ensure the Container can expand full width
      backgroundColor: '#000000', // Black background for contrast
    }}>
      <Box sx={{ 
        maxWidth: '800px', // Maximum width of the content
        width: '100%', // Take up 100% of the container width
        textAlign: 'left',
        paddingLeft: '16px', // Adjust left padding to move text more to the left
        paddingRight: 2, // Right padding for some spacing
      }}>
       <Typography variant="h3" sx={{ fontWeight: 'bold', mb: 3, textAlign: 'left' }}>
          <span style={{ color: '#FFA500' }}>Fluid Base</span>
          <span style={{ color: '#FFFFFF' }}> ETH Balance API Documentation</span>
       </Typography>
    

        <Typography variant="h5" sx={{ mb: 2, textAlign: 'left', color: '#FFFFFF' }}>
         <span style={{ color: '#FFFFFF' }}>(</span>
         <span style={{ color: '#A9A9A9' }}> For both </span>
    <span style={{ color: '#FFFFFF' }}> Web </span>
    <span style={{ color: '#A9A9A9' }}> & </span>
    <span style={{ color: '#FFFFFF' }}> Mobile </span>
    <span style={{ color: '#48d1cc' }}> Integration</span>
    <span style={{ color: '#FFFFFF' }}>)</span>
        </Typography>




        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2, textAlign: 'left', color: 'FFFFFF' }}>
          Introduction
        </Typography>
        <Typography sx={{ mb: 2, color: '#FFFFFF' }}>
          Welcome to the Fluid Base ETH Balance Checker API. This API enables you to check the balance of Ethereum (ETH) wallet addresses effortlessly. It's designed to be integrated into your applications, providing the capability to obtain real-time balance data for ETH wallets. Additionally, for users who already possess an ETH wallet or intend to create one, our API is compatible with renowned wallet services such as MetaMask and MyEtherWallet. Whether you're linking to existing wallets or assisting in the establishment of new ones, our API is designed to deliver accurate and current balance details.
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2, textAlign: 'left', color: '#FFFFFF' }}>
          Getting Started
        </Typography>
        <Typography sx={{ mb: 2, color: '#FFFFFF' }}>
          To get started using this API, follow these simple steps:
        </Typography>
        <Typography variant="subtitle1" sx={{ mb: 2, textAlign: 'left', color: '#FFFFFF' }}>
          Include the HTML and JavaScript Code:
        </Typography>
        <Typography sx={{ mb: 6, color: '#FFFFFF' }}>
          Add the following HTML code to your webpage:
        </Typography>
        {/* Your HTML and JavaScript code here */}




                <Box sx={{
             backgroundColor: '#333333', // Slightly white transparent background
             borderRadius: '8px', // Rounded corners
             p: 2, // Padding inside the box
             mb: 6, // Margin bottom for spacing
             boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)', // Shadow for depth
             fontFamily: 'monospace', // Font for code
  
            }}>
     
   
                 <iframe
                    src="https://fluidspot.s3.us-east-2.amazonaws.com/web/Base/APIdocs.html"
                    style={{ width: '100%', height: '600px', border: 'none' }}
                    title="Embedded Webpage"
                    allowFullScreen
                ></iframe>


                </Box>





                <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2, textAlign: 'left', color: '#FFFFFF' }}>
          
       Use the JavaScript Code:
        </Typography>
        <Typography sx={{ mb: 2, color: '#FFFFFF' }}>
        Include the following JavaScript code in your webpage 
        </Typography>
      
        <Typography sx={{ mb: 6, color: '#FFFFFF' }}>
        to call the API and display the ETH balance:
        </Typography>





        <Box sx={{
             backgroundColor: '#333333', // Slightly white transparent background
             borderRadius: '8px', // Rounded corners
             p: 2, // Padding inside the box
             mb: 6, // Margin bottom for spacing
             boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)', // Shadow for depth
             fontFamily: 'monospace', // Font for code
  
            }}>
     
   
                 <iframe
                    src="https://fluidspot.s3.us-east-2.amazonaws.com/web/Base/APIendpoint.html"
                    style={{ width: '100%', height: '425px', border: 'none' }}
                    title="Embedded Webpage"
                    allowFullScreen
                ></iframe>


                </Box>












      </Box>
    </Container>
   
   <SiteFooter />
    </PageLayout>
   
  
 );
};

export default APIdocs;

