import React from 'react';
import { Box, Typography, Container } from '@mui/material';
import PageLayout from '../common/PageLayout';

const Hydra = () => {
 return (
   <PageLayout>
     <Box sx={{
       width: '100vw', // Set width to 100% of the viewport width
       height: '100vh', // Set height to 100% of the viewport height
       backgroundColor: '#333333', // Slightly white transparent background
       borderRadius: '8px', // Rounded corners
       p: 2, // Padding inside the box
       mb: 6, // Margin bottom for spacing
       boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)', // Shadow for depth
       fontFamily: 'monospace', // Font for code
     }}>
       <iframe
         src="https://dev.d2u34v7n94yxaf.amplifyapp.com/"
         style={{ width: '100%', height: '100%', border: 'none' }}
         title="Embedded Webpage"
         allowFullScreen
       ></iframe>
     </Box>
   </PageLayout>
 );
};

export default Hydra;
