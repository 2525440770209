import React from 'react';
import { Box, Container } from '@mui/material';
import PageLayout from '../common/PageLayout';
import SiteFooter from '../common/SiteFooter';


const APIsuite = () => {
 return (
   <PageLayout>
 
    <Container sx={{
      mt: 2, 
      mb: 4, 
      display: 'flex', 
      justifyContent: 'flex-start', // Align to the left instead of center
      maxWidth: '100%', // Ensure the Container can expand full width
      backgroundColor: '#000000', // Black background for contrast
    }}>
      
      <Box sx={{ 
      maxWidth: '100%', // Max width of the container
      maxHeight: '100vh', // Max height of the container
      display: 'flex', // Use flexbox for centering
      justifyContent: 'center', // Center horizontally
      alignItems: 'center', // Center vertically
      overflow: 'hidden', // Hide any overflow
    }}>

          <img 
  src="https://fluidspot.s3.us-east-2.amazonaws.com/web/Base/Screenshot+2024-03-14+at+1.43.19%E2%80%AFAM.png"
  alt=""
  style={{
    maxWidth: '100%',
    maxHeight: '100vh',
    objectFit: 'contain'
  }}
     />
    
    </Box>

  


    </Container>
   
   <SiteFooter />
    </PageLayout>
   
  
 );
};

export default APIsuite;

