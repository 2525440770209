import React, { useState } from 'react';
import { Modal, Grid, Typography, Box, Link as MuiLink, Backdrop, Fade } from '@mui/material';
import { Link } from 'react-router-dom';




// Define your color palette
const colors = {
  floatingMenuBackground: '#0a0710',
  sectionTitle: '#48d1cc',
  individualLabel: '#fdfdfd',
  developerLabel: '#fdfdfd',
};

// S3 Bucket URLs for the icons
const WalletIconUrl = 'https://fluidspot.s3.us-east-2.amazonaws.com/web/Base/media_files/fluid23.png'; // Update with your S3 URL
const AccountsIconUrl = 'https://fluidspot.s3.us-east-2.amazonaws.com/web/Base/media_files/Artboard-1.png'; // Update with your S3 URL
const NFTIconUrl = 'https://fluidspot.s3.us-east-2.amazonaws.com/web/Base/media_files/NFT-copy-2.png'; // Update with your S3 URL
const FLUSD = 'https://fluidspot.s3.us-east-2.amazonaws.com/web/Base/media_files/FLUSD.png'; // Update with your S3 URL
const FluidBaseAPIUrl = 'https://fluidspot.s3.us-east-2.amazonaws.com/web/Base/media_files/webl1.png'; // Update with your S3 URL
const BaseWalletAPIUrl = 'https://fluidspot.s3.us-east-2.amazonaws.com/web/Base/media_files/walletE-API+copy.png'; // Update with your S3 URL
const BaseMarketUrl = 'https://fluidspot.s3.us-east-2.amazonaws.com/web/Base/media_files/Market_API.png'; // Update with your S3 URL
const Dapps = 'https://fluidspot.s3.us-east-2.amazonaws.com/web/Base/media_files/Dapp10.png'; // Update with your S3 URL
const Stream = 'https://fluidspot.s3.us-east-2.amazonaws.com/web/Base/media_files/Stream3.png'; // Update with your S3 URL

// Individual Section Icons with S3 URLs and color option
const individualIcons = [
  { iconUrl: WalletIconUrl, label: 'Wallet', labelColor: colors.individualLabel, path: '/wallet' },
  { iconUrl: AccountsIconUrl, label: 'Accounts', labelColor: colors.individualLabel, path: '/accounts' },
  { iconUrl: NFTIconUrl, label: 'NFT', labelColor: colors.individualLabel, path: '/nft' },
  { iconUrl: FLUSD, label: 'FLUSD', labelColor: colors.individualLabel, path: '/flusd' },
  { iconUrl: Stream, label: 'Stream', labelColor: colors.individualLabel, path: '/stream' },

  // Add other individual icons with paths here
];

const developerIcons = [
  { iconUrl: FluidBaseAPIUrl, label: 'Base API', labelColor: colors.developerLabel, path: '/baseapi' },
  { iconUrl: BaseWalletAPIUrl, label: 'Wallet API', labelColor: colors.developerLabel, path: '/wallet-api' },
  { iconUrl: BaseMarketUrl, label: 'Market API', labelColor: colors.developerLabel, path: '/market' },
  { iconUrl: Dapps, label: 'DApps', labelColor: colors.developerLabel, path: '/dapps' },
  
  // Add developer icons with paths here
];



function IconGridItem({ iconUrl, label, labelColor, path }) {

  // Define a hover style using the sx prop for MUI components
  const hoverStyle = {
    '&:hover': {
      bgcolor: 'rgba(255, 255, 255, 0.2)', // Example hover background color
      borderRadius: '4px', // Optional: adds rounded corners on hover
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textDecoration: 'none',
    color: 'inherit',
    width: '100%', // Ensure the hover effect covers the item area
  };





  return (
    <Grid item xs={4} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}>
    {/* Wrap the Link component with Box to apply hover effect */}
    <Box sx={hoverStyle}>
        <MuiLink component={Link} to={path} sx={{ textDecoration: 'none', color: 'inherit', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <img src={iconUrl} alt={label} style={{ width: '5rem', height: '5rem' }} />
      <Typography variant="caption" style={{ color: labelColor }}>{label}</Typography>
      </MuiLink>
      </Box>
    </Grid>
  );
}


function FloatingMenuExample({ onMenuItemClick }) {
  // Initialize the open state to true so the modal is open by default
  const [open, setOpen] = useState(true);

  const handleClose = () => setOpen(false);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Fade in={open}>
        <Box sx={{
          position: 'absolute',
          top: '80px',
          right: '80px',
          width: 'auto',
          maxWidth: '380px',
          overflow: 'hidden',
          padding: '20px',
          backgroundColor: colors.floatingMenuBackground,
          borderRadius: '20px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          zIndex: 1000,
          outline: 'none',
        }}>

     {/* Floating menu content */}
     <Typography variant="h6" sx={{
            fontSize: '14px',
            color: colors.sectionTitle,
            marginTop: '10px',
            marginBottom: '5px',
            fontWeight: 'bold',
          }}>
            INDIVIDUALS
          </Typography>

      <Grid container spacing={4} justifyContent="flex-start"> {/* Align items to start */}
        {individualIcons.map((item, index) => (
          <IconGridItem
            key={index}
            iconUrl={item.iconUrl}
            label={item.label}
            labelColor={item.labelColor}
            path={item.path}
          />
        ))}
      </Grid>

      <Typography variant="h6" sx={{
        fontSize: '14px',
        color: colors.sectionTitle,
        marginTop: '20px',
        marginBottom: '5px',
        fontWeight: 'bold',
      }}>
        DEVELOPERS
      </Typography>
    
      <Grid container spacing={4} justifyContent="flex-start">
            {developerIcons.map((item, index) => (
              <IconGridItem
                key={index}
                iconUrl={item.iconUrl}
                label={item.label}
                labelColor={item.labelColor}
                path={item.path}
              />
            ))}
          </Grid>

          </Box>
      </Fade>
    </Modal>
  );
}

export default FloatingMenuExample;
