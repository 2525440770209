import React, { useState, useEffect, useRef } from 'react';
import { Box, IconButton, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import APIdocs from './APIdocs'; // Import the APIdocs component
import PageLayout from '../common/PageLayout';
import APIendpoint from './APIendpoint';
import APIsuite from './APIsuite';









const sidebarOptions = [
  { 
    name: 'Base API docs', 
    icon: <img src="https://fluidspot.s3.us-east-2.amazonaws.com/web/Base/media_files/APIdocslogo.png" alt="API Documentation" width="50" height="50" />, 
    screen: <APIdocs /> // Use the imported APIdocs component here
  },


  
  { 
    name: 'API Endpoint', 
    icon: <img src="https://fluidspot.s3.us-east-2.amazonaws.com/web/Base/media_files/ring41.png" alt="API Endpoint" width="50" height="50" />, 
    screen: <APIendpoint /> // Use the imported APIdocs component here
  },
  
  { 
    name: 'API Suite', 
    icon: <img src="https://fluidspot.s3.us-east-2.amazonaws.com/web/Base/media_files/fld12.png" alt="API Endpoint" width="50" height="50" />, 
    screen: <APIsuite /> // Use the imported APIdocs component here
  },
 ];
 
 
// Sidebar component
const Sidebar = ({ options, onOptionClick }) => (
  <List sx={{ marginTop: '64px' }}>
      {options.map((option, index) => (
        <ListItem button key={index} onClick={() => onOptionClick(option.screen)} sx={{ alignItems: 'flex-start' }}>
          <ListItemIcon sx={{ marginRight: '35px' }}>{option.icon}</ListItemIcon> {/* Adjust marginRight to add space */}
          <ListItemText primary={option.name} />
        </ListItem>
      ))}
  </List>
 );
 

// BaseAPI component
const BaseAPI = () => {
 const [isSidebarOpen, setIsSidebarOpen] = useState(false);
 const [currentScreen, setCurrentScreen] = useState(<APIdocs />); // Default screen
 const sidebarRef = useRef(null); // Reference to the sidebar

 const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
 };

 const handleOptionClick = (screen) => {
    setCurrentScreen(screen);
    setIsSidebarOpen(false); // Optionally close the sidebar after selecting an option
 };

 const sidebarWidth = isSidebarOpen ? '250px' : '75px'; // Adjust width based on open state

   // Use a ref for the main content area
 const contentRef = useRef(null);


 useEffect(() => {
  // When the sidebar state changes, adjust the margin of the content area
  if (contentRef.current) {
    contentRef.current.style.marginLeft = sidebarWidth;
  }
}, [isSidebarOpen, sidebarWidth]);



 useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setIsSidebarOpen(false); // Close the sidebar if the click is outside
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
 }, []);




 return (
  <PageLayout>
    <div>
      <Box sx={{ display: 'flex', height: '100vh', backgroundColor: '#000000' }}>
        {/* Sidebar */}
        <Box
          ref={sidebarRef} // Attach the ref to the sidebar
          sx={{
            width: sidebarWidth,
            height: '100%',
            position: 'fixed',
            left: 0,
            top: 0,
            transition: 'width 0.3s ease-in-out',
            overflowX: 'hidden',
            backgroundColor: '#2c3539', // Updated sidebar background color
            marginTop: '64px', // Push down to avoid overlapping the app bar
          }}
        >
          <Sidebar options={sidebarOptions} onOptionClick={handleOptionClick} />
        </Box>

          {/* Main Content */}
          <Box
          ref={contentRef}
          component="main"
          sx={{
            flexGrow: 1,
            transition: 'margin-left 0.3s ease-in-out',
            // marginLeft set by ref in useEffect
          }}
        >
          {currentScreen}
        </Box>

        {/* Toggle Button */}
        <Box
          sx={{
            position: 'fixed',
            left: 15,
            top: '64px',
            zIndex: 1300,
          }}
        >
          <IconButton
            onClick={toggleSidebar}
            sx={{
              color: 'white',
              backgroundColor: isSidebarOpen ? '#0a0710' : 'transparent', // Change color based on sidebar state
              '&:hover': {
                backgroundColor: '#0a0710', // Highlight on hover
              },
            }}
          >
            {isSidebarOpen ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
        </Box>
      </Box>
    </div>
  
   </PageLayout>
 );
};

export default BaseAPI;
