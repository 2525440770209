import { Route, Routes, useLocation } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { Authenticator, View, Heading, Text, Image, useTheme } from '@aws-amplify/ui-react';
import awsExports from './aws-exports';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@aws-amplify/ui-react/styles.css';


import SiteNav from './components/common/SiteNav';
import SiteFooter from './components/common/SiteFooter';
import BaseAPI from './components/baseapi/BaseAPI';
import HomePage from './components/home/HomePage';
import Contacts from './components/contacts/Contacts';
import MarketComponent from './components/market/MarketComponent'; // Make sure to create this component

import DappsPage from './components/dapps/DappsPage';
import FLUSD from './components/flusd/FLUSD';
import DAppCreationView from './components/dapps/DAppCreationView';
import DAppCreatedCardView from './components/dapps/DAppCreatedCardView';
import FluidStream from './components/stream/FluidStream';


// Configure AWS Amplify
Amplify.configure(awsExports);

function App() {


  const location = useLocation();
  const showFooter = location.pathname !== '/baseapi';
  // Define custom components for the Authenticator
  const components = {
    Header() {
      const { tokens } = useTheme();
      return (
        <View textAlign="center" padding={tokens.space.large} style={{ marginBottom: tokens.space.xl }}>
          <Image
            alt="YourApp Logo"
            src="https://fluidspot.s3.us-east-2.amazonaws.com/fluid_assets_icons/WebLogo4.png"
            style={{ maxWidth: '300px', marginBottom: '10px' }} // Increased marginBottom for space below the logo
         />
             <Heading 
                level={5} 
                style={{ 
                marginTop: '10px',
                textAlign: 'center',
                }} >
                <span style={{ color: '#48d1cc' }}>Dive</span>
                <span style={{ color: '#FAEBD7' }}> in </span>
                <span style={{ color: '#8a2be2' }}> for More !!</span>
             </Heading>
          <Text 
            variation="secondary" 
            style={{ 
              color: '#CCCCCC',
              textAlign: 'center', // Aligns text to the left
            }} >
      
          </Text>

        </View>
      );
    },
    
    Footer() {
      const { tokens } = useTheme();
      return (
        <View textAlign="center" padding={tokens.space.large} style={{ marginTop: tokens.space.large }}>
          <Text color={tokens.colors.neutral[80]} style={{ marginTop: tokens.space.medium }}>
            © 2024 Fluid
          </Text>
        </View>
      );
    },
    

    SignIn: {
      Header() {
        return null; // No header for sign-in, using custom Header above.
      },
      Footer() {
        return (
          <View className="auth-footer">
            <Text>Don't have an account? <a href="/sign-up">Sign up</a></Text>
          </View>
          
        );
      },
      // ... override other components as needed
    },
    // ... add other custom components if needed
  };

  // Render the app with the Authenticator and routes
  return (
    <Authenticator loginMechanisms={['email']} components={components} className="auth-container">
      {({ signOut, user }) => (
        <div className="app-container" style={{ paddingTop: '64px' }}>

          <SiteNav logOut={signOut} user={user} />
          <Routes>
            <Route path="*" element={<HomePage />} />
            <Route path="/" element={<HomePage />} />
            <Route path="/contacts" element={<Contacts />} />
            <Route path="/market" element={<MarketComponent />} />
            <Route path="/flusd" element={<FLUSD />} />
            <Route path="/baseapi" element={<BaseAPI />} />
            <Route path="/stream" element={<FluidStream />} />
            <Route path="/dapps" element={<DappsPage />} />
            <Route path="/dapps/create" element={<DAppCreationView />} />
            <Route path="/dapps/:id" element={<DAppCreatedCardView />} />
            


            
            
            {/* Add more routes as needed */}
          </Routes>

            {/* Conditionally render SiteFooter */}
            {showFooter && <SiteFooter />}


    
        </div>
      )}
    </Authenticator>
  );
}

export default App;
