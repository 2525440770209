
import React from 'react';
import { Box, Typography, Container } from '@mui/material';
import PageLayout from '../common/PageLayout';



const FLUSD = () => {
  // Example state to manage the amount of FLUSD



  return (
    <PageLayout>
 
    <Container sx={{
      mt: 5, 
      mb: 8, 
      display: 'flex', 
      justifyContent: 'flex-start', // Align to the left instead of center
      maxWidth: '100%', // Ensure the Container can expand full width
      backgroundColor: '#000000', // Black background for contrast
    }}>
      <Box sx={{ 
        maxWidth: '800px', // Maximum width of the content
        width: '100%', // Take up 100% of the container width
        textAlign: 'left',
        paddingLeft: '16px', // Adjust left padding to move text more to the left
        paddingRight: 2, // Right padding for some spacing
      }}>
       <Typography variant="h3" sx={{ fontWeight: 'bold',mt: 4, mb: 3, textAlign: 'left' }}>
          <span style={{ color: '#48d1cc' }}>FLUSD</span>
          <span style={{ color: '#FFFFFF' }}> Stablecoin</span>
       </Typography>
      

        <Typography sx={{ mb: 2, color: '#FFFFFF' }}>
      FLUSD: A Dollar-Pegged Stablecoin, 1:1 Backed by U.S. Dollar Reserves — Secure and Stable on the Fluid Platform.
        </Typography>

      <Typography variant="h5" sx={{ mb: 6, textAlign: 'left', color: '#FFFFFF' }}>
           <span style={{ color: '#FFFFFF' }}>(</span>
           <span style={{ color: '#FFFFFF' }}> 1 USD</span>
           <span style={{ color: '#A9A9A9' }}> = </span>
           <span style={{ color: '#FFFFFF', marginRight: '8px' }}>1 FLUSD</span> {/* Added marginRight for spacing */}
          
           <span style={{ color: '#FFFFFF' }}>)</span>
      </Typography>
   

    
      <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', mt: 2, mb:10 }}>
        <img src="https://fluidspot.s3.us-east-2.amazonaws.com/web/Base/media_files/FLUSD.png" alt="FLUSD Token" style={{ width: '200px', height: '200px' }} />
      </Box>
        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2, textAlign: 'left' }}>
          <span style={{ color: '#FFE0B2' }}>(Coming Soon)</span>
        </Typography>

      

        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2, textAlign: 'left', color: '#FFFFFF' }}>
          Introducing the New FLUSD Stablecoin 
        </Typography>
        <Typography sx={{ mb: 4, color: '#FFFFFF' }}>
        FLUSD ushers in a new era of digital currency, grounded in a solid, infrastructure-driven reserve and supercharged by staking networks and yielding mechanisms. Powered by smart contract ledgers, and distributed globally while being decentralized everywhere, FLUSD guarantees unparalleled security, stability, and growth potential with every transaction. Experience the assurance and innovation of FLUSD, embodying our dedication to global accessibility and reliability.  </Typography>
       
       
       
        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2, textAlign: 'left', color: '#FFFFFF' }}>
        Seamless Transactions
        </Typography>
        <Typography sx={{ mb: 4, color: '#FFFFFF' }}>
        Acquire, dispose, retain, and move Fluid USD effortlessly through our application or website. Share it fee-free with peers within the US via Fluid, or effortlessly dispatch it to Ethereum wallet addresses compatible with FLUSD in a straightforward process. Be aware, network charges could apply.
        </Typography>
     
        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2, textAlign: 'left', color: '#FFFFFF' }}>
        Streamlined Payments and Beyond
        </Typography>
        <Typography sx={{ mb: 2, color: '#FFFFFF' }}>
          Use your FLUSD stablecoin for checkout, and we'll seamlessly convert your Fluid USD for the purchase. Additionally, leverage our conversion tool to exchange FLUSD for other supported cryptocurrencies. Please note, transaction fees are applicable."
        </Typography>


  

























      </Box>
    </Container>
   

    </PageLayout>
   
  );
};

export default FLUSD;
