import React from 'react';
import PageLayout from '../common/PageLayout';
import { Box, Typography, Container } from '@mui/material';


const FluidStream = () => {
  // Set this to the fixed height of your AppBar
  // This value should match your AppBar's height to ensure content starts below the AppBar

  return (
    <PageLayout>
      {/* Typography Wrapper Box */}
    
      <Box sx={{ 
        mt: 0, // Set marginTop to 0 to remove the gap
        height: '100vh', // Set height to 100% of the viewport height
        overflow: 'auto',
        backgroundColor: '#333333'
      }}>
        <iframe 
          src="https://dev.d25inzn2zinrjk.amplifyapp.com/" 
          title="Market"
          width="100%"
          height="100%"
          style={{ border: 'none', display: 'block' }} // Ensures iframe fills the container without extra space
        >
   
        </iframe>
      </Box>

    </PageLayout>
  );
}

export default FluidStream;
