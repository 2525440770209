// src/components/common/PageLayout.js
import React from 'react';
import { Container } from 'react-bootstrap';

import '../../App.css'; // Adjusted the path to go up two levels to the src directory

const PageLayout = ({ children }) => {
  const navbarHeight = 10; // Adjust this value to match your navbar's height

  return (
    <>
     
      <Container fluid style={{ paddingTop: `${navbarHeight}px` }}>
        {children}
      </Container>
    </>
  );
};

export default PageLayout;
