import React from 'react';
import PageLayout from '../common/PageLayout';
import { Box, Typography, Container } from '@mui/material';


const MarketComponent = () => {
  // Set this to the fixed height of your AppBar
  // This value should match your AppBar's height to ensure content starts below the AppBar

  return (
    <PageLayout>
      {/* Typography Wrapper Box */}
      <Box sx={{ 
        mt: 4, // Adjusted to match the top margin of the first Typography
        mb: 2, // Bottom margin to space it from the next Box
        textAlign: 'left',
        pl: 4, // Aligns text to the left
      }}>
        <Typography variant="h3" sx={{ 
            fontWeight: 'bold', 
            mb: 3, // Space between this Typography and the next one
          }}>
          <span style={{ color: '#FFE5B4' }}>DeFi</span>
          <span style={{ color: '#FFFFFF' }}> Crypto Exchange</span>
        </Typography>

        <Typography variant="h6" sx={{ 
            fontWeight: 'bold', 
            color: '#FFFFFF',
          }}>
          Dive in to Track your Favourite Cryptocurrencies
        </Typography>
      </Box>
      <Box sx={{ 
        mt: 0, // Set marginTop to 0 to remove the gap
        height: '100vh', // Set height to 100% of the viewport height
        overflow: 'auto',
        backgroundColor: '#333333'
      }}>
        <iframe 
          src="https://dev.d29ggolxg4oj0f.amplifyapp.com/" 
          title="Market"
          width="100%"
          height="100%"
          style={{ border: 'none', display: 'block' }} // Ensures iframe fills the container without extra space
        >
   
        </iframe>
      </Box>

    </PageLayout>
  );
}

export default MarketComponent;
