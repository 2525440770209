import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu, useMediaQuery, useTheme } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Container from '@mui/material/Container';
import { Image } from 'react-bootstrap';
import MenuBar from '@mui/icons-material/Menu'; // Renamed MenuIcon to MenuBar
import Popover from '@mui/material/Popover'; // Import Popover from MUI
import FloatingMenu from './FloatingMenu'; // Import your FloatingMenu component
import GenerateApiKeyModal from './Modal'; // Adjust the path as necessary






function SiteNav(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null); // State for the popover menu
  const [floatingMenuAnchorEl, setFloatingMenuAnchorEl] = useState(null);
 


  const [anchorElNav, setAnchorElNav] = useState(null);
  const theme = useTheme();
  // Adjust this breakpoint as needed to better fit your design requirements
  const isMenuNeeded = useMediaQuery(theme.breakpoints.down('lg'));


  //States for <MenuIcon> Box 
  const [menuIconAnchorEl, setMenuIconAnchorEl] = useState(null);
  const [isMenuIconPopoverOpen, setIsMenuIconPopoverOpen] = useState(false);
  //States for <AccountCircle> Box
  const [accountCircleAnchorEl, setAccountCircleAnchorEl] = useState(null);
  const [isAccountCirclePopoverOpen, setIsAccountCirclePopoverOpen] = useState(false);


 //  const navigate = useNavigate();




  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };





   // Handlers for <MenuIcon> Box:
  const handleMenuIconHover = (event) => {
    setMenuIconAnchorEl(event.currentTarget);
    setIsMenuIconPopoverOpen(true);
  };
  
  const handleMenuIconClose = () => {
    setIsMenuIconPopoverOpen(false);
  };
  
  const handleMenuIconClick = (event) => {
    setMenuIconAnchorEl(event.currentTarget);
    setIsMenuIconPopoverOpen(!isMenuIconPopoverOpen);
  };
  


  //Handlers for <AccountCircle> Box:
  const handleAccountCircleHover = (event) => {
    setAccountCircleAnchorEl(event.currentTarget);
    setIsAccountCirclePopoverOpen(true);
  };
  
  const handleAccountCircleClose = () => {
    setIsAccountCirclePopoverOpen(false);
  };
  
  const handleAccountCircleClick = (event) => {
    setAccountCircleAnchorEl(event.currentTarget);
    setIsAccountCirclePopoverOpen(!isAccountCirclePopoverOpen);
  };
  


  




  // State to control the visibility of the modal for generating API key
  const [isGenerateKeyModalOpen, setGenerateKeyModalOpen] = useState(false);

// Replace navigateToGenerateKey with a function that toggles the modal
  const navigateToGenerateKey = () => {
     setGenerateKeyModalOpen(true); // Set the state to true to open the modal
  };

    // Function to close the generate key modal
  const handleCloseGenerateKeyModal = () => {
     setGenerateKeyModalOpen(false); // Set the state to false to close the modal
  };



  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuAnchorEl(null); // Close both menus
  };

  const handleLogout = () => {
    handleMenuClose();
    if (props.logOut) {
      props.logOut();
    }
  };

  const menuItems = [
    { label: 'Home', path: '/home' },
    { label: 'Market', path: '/market' },
    { label: 'Portfolio', path: '/portfolio' },
    { label: 'Rewards', path: '/rewards' },
  ];

 // const isMenuOpen = Boolean(anchorEl);
 // const isPopoverOpen = Boolean(menuAnchorEl); // Whether the popover menu is open
 //  const isFloatingMenuOpen = Boolean(floatingMenuAnchorEl);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" style={{ background: '#0a0710' }}>
        <Container maxWidth="xl">
        <Toolbar disableGutters sx={{ paddingRight: '25px', display: 'flex', justifyContent: 'space-between' }}> 

    
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="logo"
                component={Link}
                to="/"
                sx={{ p: 0 }}
              >
                <Image
                  alt="Logo"
                  src="https://fluidspot.s3.us-east-2.amazonaws.com/fluid_assets_icons/icon110.png"
                  height="60"
                />
              </IconButton>

              <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                variant="h6"
                noWrap
                component={Link}
                to="/"
                sx={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center', marginRight: 2 }}
              >
                Dashboard
              </Typography>
              </Box>

            


          

         


{isMenuNeeded ? (
  <>
    <IconButton
      size="large"
      aria-label="account of current user"
      aria-controls="menu-appbar"
      aria-haspopup="true"
      onClick={handleOpenNavMenu}
      color="inherit"
      sx={{
        mr: '-165px', // Adjust this value as needed to move the MenuBar icon closer to the Dashboard text
      }}
    >
      <MenuBar /> {/* Updated from MenuIcon to MenuBar */}
    </IconButton>
    <Menu
      id="menu-appbar"
      anchorEl={anchorElNav}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(anchorElNav)}
      onClose={handleCloseNavMenu}
    >
      {menuItems.map((item) => (
        <MenuItem key={item.label} onClick={handleCloseNavMenu}>
          <Link to={item.path} style={{ color: '#000', textDecoration: 'none' }}>{item.label}</Link>
        </MenuItem>
      ))}
    </Menu>
  </>
) : (
  <Box sx={{ display: { xs: 'none', lg: 'flex' }, mr: 'auto', gap: 1.5, 
    '& a': {
      color: 'white',
      textDecoration: 'none',
      padding: '0 2px',
      '&:hover': {
        bgcolor: 'grey.700',
        borderRadius: '4px' // Optional: To give a rounded effect on hover
      }
    }
  }}>
    {menuItems.map((item) => (
      <Link
        key={item.label}
        to={item.path}
        sx={{
          '&:hover': {
            bgcolor: 'grey.700',
            borderRadius: '4px' // Optional: To give a rounded effect on hover
          }
        }}
      >
        {item.label}
      </Link>
    ))}
  </Box>
)}

</Box>










<Box sx={{ display: 'flex', alignItems: 'center' }}>
    {/* Box for MenuIcon with hover and click functionalities */}
    <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 5 }}>
            
    <IconButton
    size="large"
    edge="end"
    aria-label="open menu"
    aria-haspopup="true"
    color="inherit"
    onMouseEnter={handleMenuIconHover} // Trigger for desktop hover
    onClick={handleMenuIconClick} // Trigger for clicks
    onTouchEnd={handleMenuIconClick} // Additional trigger for touch devices
    sx={{
      p: 0, // Adjust padding as needed
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.08)', // Example hover background color
      },
    }}
  >
    <img
      src="https://fluidspot.s3.us-east-2.amazonaws.com/web/Base/media_files/FLD3_edited_edited_edited.png"
      alt="Menu"
      style={{ height: '22px', width: '64px' }}
    />
  </IconButton>

              <Popover
                 id="menu-popover"
                 open={isMenuIconPopoverOpen}
                 anchorEl={menuIconAnchorEl}
                 onClose={handleMenuIconClose}
                 anchorOrigin={{
                 vertical: 'bottom',
                 horizontal: 'left',
                 }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
                }}
                disableRestoreFocus // This prevents focus from returning to the button after closing the popover
                PaperProps={{
                style: {
                backgroundColor: 'transparent', // This will make the popover background transparent
                boxShadow: 'none', // This will remove the shadow if it's not needed
                // Add any other styles you want to apply to the popover here
                },
               }}
               >
               <FloatingMenu onMenuItemClick={handleMenuClose} />
               </Popover>

        
            </Box>



             {/* Box for AccountCircle with hover and click functionalities */}
             <Box sx={{ display: 'flex', alignItems: 'center' }}>


              {/* AccountCircle IconButton */}
               {/* IconButton for the new floating menu */}
            <IconButton
               size="large"
               edge="start"
               aria-label="open floating menu"
               aria-haspopup="true"
               onMouseEnter={handleAccountCircleHover}
               onClick={handleAccountCircleClick}
               color="inherit"
            >
          <AccountCircle />
        </IconButton>
 {/* New Popover for the new floating menu */}
 <Popover
  id="floating-menu-popover"
  open={isAccountCirclePopoverOpen}
  anchorEl={accountCircleAnchorEl}
  onClose={handleAccountCircleClose}
  anchorOrigin={{
    vertical: 'bottom',
    horizontal: 'left',
  }}
  transformOrigin={{
    vertical: 'top',
    horizontal: 'left',
  }}
  disableRestoreFocus
>
  <Box sx={{ 
    p: 2,
    bgcolor: 'black',
    color: 'white',
    '& .MuiMenuItem-root': {
      color: 'white',
      '&:hover': {
        bgcolor: 'grey.700',
      }
    }
  }}> 
    {/* Existing Menu items */}
    <MenuItem onClick={() => {/* Navigate to Profile */}} sx={{ color: 'inherit' }}>Profile</MenuItem>
    <MenuItem onClick={navigateToGenerateKey} sx={{ color: 'inherit' }}>Generate API Key</MenuItem>

    <MenuItem onClick={() => {/* Navigate to Settings */}} sx={{ color: 'inherit' }}>Settings</MenuItem>
    <MenuItem onClick={handleLogout} sx={{ color: 'inherit' }}>Logout</MenuItem>
  </Box>
</Popover>

</Box>





  </Box>








     






 
          </Toolbar>
        </Container>
      </AppBar>

      <GenerateApiKeyModal
    isOpen={isGenerateKeyModalOpen}
    onClose={handleCloseGenerateKeyModal}
  />

    
    </Box>


  );
}

export default SiteNav;
