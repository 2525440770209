import React from 'react';
import Hydra from './Hydra';

const DAppCreatedCardView = () => {
 return (
    <div style={{ width: '100vw', height: '100vh' }}>
     <Hydra />
    </div>
 );
};

export default DAppCreatedCardView;
