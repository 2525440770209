import React, { useState } from 'react';
import { Container, Stepper, Step, StepLabel, Button, TextField, Typography, Box } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

const steps = ['Step 1 of 5', 'Step 2 of 5', 'Step 3 of 5', 'Step 4 of 5', 'Step 5 of 5'];

const getStepContent = (stepIndex) => {
  switch (stepIndex) {
    case 0:
      return 'Let\'s start with a name for your DApp';
    case 1:
      return 'Step 2 content here';
    case 2:
      return 'Step 3 content here';
    case 3:
      return 'Step 4 content here';
    case 4:
      return 'Step 5 content here';
    default:
      return 'Unknown step';
  }
}

const DappForm = () => {
  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();
  const { dappId } = useParams(); // Assuming you're passing the dapp ID in the URL

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };


  // Finish the form and navigate back to the list of DApps
  const finishForm = () => {
    // Here you would typically handle the form submission, e.g. POST to an API
    // After submission, navigate to the DApps page or to the newly created DApp's detail view
    navigate('/dapps');
  };

  // Redirect user to the Dapp detail page if they finish the form
  if (activeStep === steps.length) {
    navigate(`/dapp-detail/${dappId}`);
  }

  return (
    <Container>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
      {activeStep === steps.length ? (
      <React.Fragment>
        <Typography>All steps completed - your new DApp is created</Typography>
        <Button onClick={finishForm}>Go to DApps List</Button>
      </React.Fragment>
    ) : (
          <div>
            <Typography>{getStepContent(activeStep)}</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2 }}>
              <Button
                variant="contained"
                onClick={handleNext}
                sx={{ mt: 1, mr: 1 }}
              >
                Next
              </Button>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mt: 1, mr: 1 }}
              >
                Back
              </Button>
            </Box>
            {activeStep === 0 && (
              <TextField label="Dapp Name" variant="filled" sx={{ mt: 2 }} />
            )}
            {/* Add more form fields based on the step */}
          </div>
        )}
      </div>
    </Container>
  );
};

export default DappForm;
